import Image from "next/image";

const VideoPlaceHolder = ({ backgroundImage, styles }) => {
  return (
      <Image
        className={styles.videoPlaceholder}
        src={`${backgroundImage}`}
        alt={'internal hero'}
        fill
        style={{
          objectFit: 'cover',
        }}
        priority={true}
      />
  );
};

export default VideoPlaceHolder;